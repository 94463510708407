import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { AccountInformation } from 'src/app/interfaces/account-information';
import { ApiResponse } from 'src/app/interfaces/api-response';
import { Dealer } from 'src/app/interfaces/dealer';
import { YearAgreementBase } from 'src/app/interfaces/year-agreement-base';
import {
  DealerLevel,
  YearAgreementPAndA,
} from 'src/app/interfaces/year-agreement-p-and-a';
import { YearAgreementType } from 'src/app/interfaces/year-agreement-type';
import { YearAgreementParts } from 'src/app/interfaces/yearAgreement/year-agreement-parts';
import { AccountService } from 'src/app/services/account.service';
import { AlertService } from 'src/app/services/alert.service';
import { ApplicationService } from 'src/app/services/application.service';
import { HelpdeskService } from 'src/app/services/helpdesk.service';
import { YearAgreementService } from 'src/app/services/year-agreement.service';
import { YearAgreementBaseService } from 'src/app/services/year-agreements/year-agreement-base.service';
import { YearAgreementPAndAService } from 'src/app/services/year-agreements/year-agreement/year-agreement-p-and-a.service';
import { YearAgreementSummaryComponent } from '../../bikes/year-agreement-summary/year-agreement-summary.component';
import { DealerCommercialPartnerInformationComponent } from '../../parts/dealer-commercial-partner-information/dealer-commercial-partner-information.component';
import { DealerPartsBrandsSelectionComponent } from '../../parts/dealer-parts-brands-selection/dealer-parts-brands-selection.component';
import { YearAgreementDealerShipsComponent } from '../../parts/year-agreement-dealer-ships/year-agreement-dealer-ships.component';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-year-agreement-pand-a',
  templateUrl: './year-agreement-p-and-a.component.html',
  styleUrls: ['./year-agreement-p-and-a.component.scss'],
})
export class YearAgreementPAndAComponent implements OnInit {
  @Output() agreementCreated: EventEmitter<number> = new EventEmitter<number>();
  @ViewChild(DealerCommercialPartnerInformationComponent)
  partnerInformation: DealerCommercialPartnerInformationComponent;
  @ViewChild('brands')
  dealerPartsBrandsSelectionComponent: DealerPartsBrandsSelectionComponent;
  @ViewChild('dealerShips')
  dealerShipSelection: YearAgreementDealerShipsComponent;
  @ViewChild('summary') summary: YearAgreementSummaryComponent;
  selectedTab: string = 'dealerInformation';
  selectedDealerInformation: Dealer;
  selectedDealer: any;
  yearAgreement: YearAgreementParts;
  invalid: boolean;
  brandsInvalid: boolean;
  yearAgreements: YearAgreementParts[];
  dealerChangeEvent: Subscription;
  dealerShipInvalid: boolean;
  commercialSettingsInvalid: boolean;
  dealerLevelInvalid: boolean;
  ts: number;
  accountInformation: AccountInformation;
  loading: boolean;
  _moment = null;
  revenueTargetInvalid: boolean;
  agreements: YearAgreementBase[];
  selectedAgreement: YearAgreementBase;
  hasMargins: boolean;
  minimalTurnover: number;
  refresh: boolean;
  constructor(
    private accountService: AccountService,
    private applicationService: ApplicationService,
    private helpdeskService: HelpdeskService,
    private alertService: AlertService,
    private translateService: TranslateService,
    public yearAgreementPAndAService: YearAgreementPAndAService,
    public yearAgreementBaseService: YearAgreementBaseService,
    public helperService: HelperService
  ) {}

  ngOnInit() {
    this._moment = moment;
    this.getDealerAgreements();
    // this.yearAgreementService.resetPartsAgreement();

    // Dealer selected
    this.dealerChangeEvent =
      this.applicationService.emitChangeCompanyGroupChanged$.subscribe(() => {
        this.getDealerAgreements();
        this.summary.pdfSrc = null;
        this.selectedTab = 'dealerInformation';
      });
  }

  ngOnDestroy() {
    if (this.dealerChangeEvent) {
      this.dealerChangeEvent.unsubscribe();
    }
  }

  getDealerAgreements() {
    this.selectedDealer = this.helpdeskService.getSelectedDealer();
    this.selectedDealerInformation = this.accountService.getDealerInformation();
    this.getAgreements();
  }

  getAgreements() {
    this.applicationService.showLoader(true);
    this.yearAgreementBaseService
      .agreements(YearAgreementType.parts)
      .subscribe((apiResponse: ApiResponse) => {
        this.applicationService.hideLoader();
        if (
          apiResponse &&
          apiResponse.success &&
          apiResponse.result &&
          apiResponse.result.length
        ) {
          this.selectedAgreement =
            apiResponse.result[apiResponse.result.length - 1];
          this.agreements = apiResponse.result.reverse();
          this.getAgreement(this.selectedAgreement);
        } else {
          this.alertService.showPopup(
            apiResponse.result
              ? apiResponse.result
              : this.translateService.instant('ERROR'),
            'error',
            30000
          );
        }
      });
  }

  getAgreement(agreement: YearAgreementBase, refresh?: boolean) {
    this.selectedAgreement = agreement;
    this.applicationService.showLoader(true);
    if (refresh) {
      this.refresh = true;
    }
    this.yearAgreementPAndAService.agreement(agreement).then(() => {
      if (this.yearAgreementPAndAService.agreementModel.year_agreement_base?.signed) {
        this.selectedTab = 'summary'
      } else{
        this.selectedTab = 'dealerInformation';
      }
      this.applicationService.hideLoader();
      this.checkBrandSelection();
      this.agreementCreated.emit(agreement.year);
      this.parseTexts();
      this.yearAgreementPAndAService.setDealerSettings();
      if (refresh) {
        this.refresh = false;
      }
    });
  }

  parseTexts() {
    let parsedText = {};
    this.yearAgreementPAndAService.agreementModel?.texts?.forEach((t) => {
      parsedText[t.key] = t.value;
    });
    this.yearAgreementPAndAService.agreementModel.parsedText = parsedText;
  }

  tabChange(data: NgbNavChangeEvent) {
    this.selectedTab = data.nextId;
    if (this.selectedTab === 'summary') {
      this.summary.getPdfPreview(YearAgreementType.parts);
    }
    if (this.selectedTab === 'brands') {
      this.checkBrandSelection();
    }
  }

  checkDealerLevel() {
    this.dealerLevelInvalid = false;
    if (
      (!this.yearAgreementPAndAService.agreementModel.selected_dealer_level &&
        this.yearAgreementPAndAService.agreementModel.selected_dealer_level !==
          0) ||
      (this.yearAgreementPAndAService.agreementModel?.bike_dealer_ships &&
        !this.yearAgreementPAndAService.agreementModel
          .selected_bike_dealer_ship) ||
      (this.yearAgreementPAndAService.agreementModel?.retail_formulas &&
        !this.yearAgreementPAndAService.agreementModel.selected_retail_formula)
    ) {
      this.dealerLevelInvalid = true;
    }
  }

  checkBrandSelection() {
    this.brandsInvalid = false;
    const selected =
      this.yearAgreementPAndAService.agreementModel.selected_dealer_level;
    const dealerLevel =
      this.yearAgreementPAndAService.agreementModel.dealer_levels.find(
        (d: DealerLevel) => {
          return String(d.id) === String(selected);
        }
      );
    if (typeof dealerLevel !== 'undefined') {
      this.hasMargins = dealerLevel.has_margins;
      this.minimalTurnover = dealerLevel.minimal_turnover
        ? dealerLevel.minimal_turnover
        : 0;
    }
  }

  checkDealerShipSelection() {
    if (typeof this.dealerShipSelection !== 'undefined') {
      this.dealerShipInvalid = this.dealerShipSelection.checkSelection() > 0;
    }
  }

  checkRevenueTarget() {
    const input = document.getElementById('revenueTarget');
    if (
      this.yearAgreementPAndAService.agreementModel.turnover_target > 0 &&
      ((this.minimalTurnover &&
        this.yearAgreementPAndAService.agreementModel.turnover_target >=
          this.minimalTurnover) ||
        !this.minimalTurnover)
    ) {
      this.revenueTargetInvalid = false;
      input.classList.remove('border-danger');
    } else {
      this.revenueTargetInvalid = true;
      input.classList.add('border-danger');
    }
  }

  checkForm() {
    this.invalid = false;
    this.checkDealerLevel();
    this.checkBrandSelection();
    this.checkDealerShipSelection();
    this.checkRevenueTarget();
    if (
      this.dealerLevelInvalid ||
      this.brandsInvalid ||
      this.dealerShipInvalid ||
      this.checkCommercialSelection() ||
      this.revenueTargetInvalid
    ) {
      this.invalid = true;
      this.alertService.showErrorNotification(
        `${this.translateService.instant('REQUIRED_FIELDS_TEXT')}`
      );
    }
    return !this.invalid ? true : false;
  }

  checkCommercialSelection() {
    const noValueElements = document.querySelectorAll('.no-value');
    if (noValueElements.length > 0) {
      return true;
    }
    return false;
  }

  save(hideNotification?: boolean) {
    return new Promise((resolve) => {
      if (this.checkForm()) {
        this.applicationService.showLoader(true);
        this.loading = true;
        this.yearAgreementPAndAService
          .saveAgreement(this.yearAgreementPAndAService.agreementModel)
          .subscribe((apiResponse: ApiResponse) => {
            if (apiResponse && apiResponse.success) {
              if (!hideNotification) {
                this.alertService.showInfoNotification(
                  this.translateService.instant('SAVED')
                );
              }
            } else {
              this.alertService.showPopup(apiResponse.result, 'error', 6000);
              resolve(false);
            }

            this.ts = moment().unix();
            this.loading = false;
            resolve(true);
          });
      } else {
        this.loading = false;
        resolve(true);
      }
    });
  }

  updateAgreement() {
    this.loading = true;
    this.yearAgreementPAndAService
      .saveAgreement(this.yearAgreementPAndAService.agreementModel)
      .subscribe((apiResponse: ApiResponse) => {
        this.loading = false;
        this.getAgreement(this.selectedAgreement, false);
      });
  }

  send() {
    this.save(true).then((success: boolean) => {
      if (success) {
        this.loading = true;
        this.summary.submit();
      }
    });
  }

  reopenAgreement() {
    this.applicationService.showLoader(true);
    this.yearAgreementBaseService
      .reOpenAgreement(
        this.yearAgreementPAndAService.agreementModel.year_agreement_base.id.toString()
      )
      .then((success: boolean) => {
        this.applicationService.hideLoader();
        if (success) {
          this.getAgreement(this.selectedAgreement, false);
          this.yearAgreementPAndAService.agreementModel.year_agreement_base.locked =
            false;

            if ( this.yearAgreementPAndAService.agreementModel.year_agreement_base?.signed) {
              this.selectedTab = 'summary';
            } else {
              this.selectedTab = 'dealerInformation';
            }
        }
      });
  }

  agreementSubmitted() {
    this.loading = false;
    this.applicationService.hideLoader();
    this.getAgreement(this.selectedAgreement, false);
    this.alertService.showInfoNotification(
      this.translateService.instant('SENT')
    );
  }

  sendException() {
    this.loading = true;
    this.applicationService.showLoader(true);
    this.save().then(() => {
      this.yearAgreementBaseService
        .sendException(
          this.yearAgreementPAndAService.agreementModel.year_agreement_base.id
        )
        .subscribe((apiResponse: ApiResponse) => {
          this.applicationService.hideLoader();
          this.loading = false;
          if (this.helperService.checkResponse(apiResponse)) {
            this.getAgreement(this.selectedAgreement, false);
            this.alertService.showInfoNotification(
              this.translateService.instant('EXCEPTION_SEND')
            );
          }
        });
    });
  }

  checkUndefined(property) {
    return typeof property === 'undefined';
  }
}
