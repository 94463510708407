import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CurrencyConvertPipe } from "src/app/pipes/currency-convert.pipe";
import { NumberFormatPipe } from "src/app/pipes/number-format.pipe";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { BasketArticleComponent } from "src/app/components/basket-components/basket/basket-article/basket-article.component";
import { ArticleImageComponent } from "src/app/components/general-components/article-image/article-image.component";
import { StatusTextPipe } from "src/app/pipes/status-text.pipe";
import { StockStatusComponent } from "src/app/components/general-components/stock-status/stock-status.component";
import { DeliveryWeekComponent } from "src/app/components/basket-components/basket/delivery-week/delivery-week.component";
import { NettPriceComponent } from "src/app/components/general-components/nett-price/nett-price.component";
import { ArticleSumComponent } from "src/app/components/basket-components/basket/article-sum/article-sum.component";
import { NumberOnlyDirective } from "src/app/directives/number-only.directive";
import { RetailPriceComponent } from "src/app/components/general-components/retail-price/retail-price.component";
import { ShopTypeSelectionComponent } from "src/app/components/basket-components/basket/shop-type-selection/shop-type-selection.component";
import { SelectWeekComponent } from "src/app/components/general-components/select-week/select-week.component";
import { SelectBasketComponent } from "src/app/components/general-components/select-basket/select-basket.component";
import { CmsPageContentComponent } from "src/app/components/general-components/cms-page-content/cms-page-content.component";
import { BreadCrumbsComponent } from "src/app/components/header-components/bread-crumbs/bread-crumbs.component";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { ArticleQuickViewDirective } from "src/app/directives/article-quick-view.directive";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { ArticleLinksComponent } from "src/app/components/article-components/article-links/article-links.component";
import { BicycleArticleComponent } from "src/app/components/general-components/bicycle-article/bicycle-article.component";
import { AddToBasketButtonComponent } from "src/app/components/basket-components/add-to-basket-button/add-to-basket-button.component";
import { SetItemButtonComponent } from "src/app/components/general-components/set-item-button/set-item-button.component";
import { LeasePriceComponent } from "src/app/components/lease/lease-price/lease-price.component";
import { InitialsDirectiveDirective } from "src/app/directives/initials-directive.directive";
import { CommonFilterComponent } from "src/app/components/search-components/side-bar/common-filter/common-filter.component";
import { ArticleImageCarouselComponent } from "src/app/components/article-components/article-image-carousel/article-image-carousel.component";
import { MatrixComponent } from "src/app/components/article-components/article-details/matrix/matrix.component";
import { InlineMatrixComponent } from "src/app/components/search-components/article-card/inline-matrix/inline-matrix.component";
import { ArticleSpecificationsComponent } from "src/app/components/article-components/article-specifications/article-specifications.component";
import { KioskModeCheckerComponent } from "src/app/components/kiosk/kiosk-mode-checker/kiosk-mode-checker.component";
import { NgOtpInputModule } from "ng-otp-input";
import { KioskArticleCardComponent } from "src/app/components/kiosk/kiosk-article-card/kiosk-article-card.component";
import { CompanyPageComponent } from "src/app/pages/company-page/company-page.component";
import { SafePipe } from "src/app/pipes/safe.pipe";
import { InterceptorModule } from "src/app/interceptor/interceptor.module";
//import { NgxGalleryModule } from "ngx-gallery-9";
import { HeaderIconComponent } from "src/app/components/general/header-icon/header-icon.component";
import { ChangePasswordContainerComponent } from "src/app/components/user-components/user-settings/change-password-container/change-password-container.component";
import { SuccessCheckIconComponent } from "src/app/components/general/success-check-icon/success-check-icon.component";
import { DealerInformationComponent } from "src/app/components/year-agreement/shared/dealer-information/dealer-information.component";
import { YearAgreementSummaryComponent } from "src/app/components/year-agreement/bikes/year-agreement-summary/year-agreement-summary.component";
import { ArticleCardComponent } from "src/app/components/search-components/article-card/article-card.component";
import { SuggestTermsComponent } from "src/app/components/search-components/suggest-terms/suggest-terms.component";
import { GrossPriceComponent } from "src/app/components/general-components/gross-price/gross-price.component";
import { HighlightBadgeComponent } from "src/app/components/general-components/highlight-badge/highlight-badge.component";
import { SpecListComponent } from "src/app/components/search-components/article-card/spec-list/spec-list.component";
import { ArticleStickBarComponent } from "src/app/components/article-components/article-stick-bar/article-stick-bar.component";
import { ArticleDetailsComponent } from "src/app/components/article-components/article-details/article-details.component";
import { ArticleOptionsComponent } from "src/app/components/article-components/article-options/article-options.component";
import { PromotionPriceComponent } from "src/app/components/general-components/promotion-price/promotion-price.component";
import { VolumePricesComponent } from "src/app/components/general-components/volume-prices/volume-prices.component";
import { SetItemsComponent } from "src/app/components/article-components/set-items/set-items.component";
import { ArticleQuestionComponent } from "src/app/components/chat-components/article-question/article-question.component";
import { EcommerceArticlesComponent } from "src/app/components/ecommerce-components/ecommerce-articles/ecommerce-articles.component";
import { HasReplacementComponent } from "src/app/components/general-components/has-replacement/has-replacement.component";
import { RecentlyViewedComponent } from "src/app/components/article-components/recently-viewed/recently-viewed.component";
import { AddToLeaseButtonComponent } from "src/app/components/lease/add-to-lease-button/add-to-lease-button.component";
import { FocusModelComponent } from "src/app/components/general-components/focus-model/focus-model.component";
import { ArticleBomComponent } from "src/app/components/article-components/article-bom-components/article-bom.component";
import { QuickSettingsComponent } from "src/app/components/general-components/quick-settings/quick-settings.component";
import { TreeViewItemComponent } from "src/app/components/search-components/tree-view/tree-view-item/tree-view-item.component";
import { TreeViewComponent } from "src/app/components/search-components/tree-view/tree-view.component";
import { SelectGroupComponent } from "src/app/components/article-components/article-bom-components/select-group/select-group.component";
import { SetItemGroupComponent } from "src/app/components/article-components/set-items/set-item-group/set-item-group.component";
import { PreorderContainerComponent } from "src/app/components/preorder-components/preorder-container/preorder-container.component";
import { PreorderMonthInputComponent } from "src/app/components/preorder-components/preorder-month-input/preorder-month-input.component";
import { ArticleListItemComponent } from "src/app/components/preorder-components/article-list-item/article-list-item.component";
import { ArticleWeeksTableComponent } from "src/app/components/preorder-components/article-weeks-table/article-weeks-table.component";
import { RouterModule } from "@angular/router";
import { GroupByPipe } from "src/app/pipes/group-by.pipe";
import { SortByPipe } from "src/app/pipes/sort-by.pipe";
import { DeliveryTextPipe } from "src/app/pipes/delivery-text.pipe";
import { FormatAccentryUrlPipe } from "src/app/pipes/format-accentry-url.pipe";
import { FormatDeliveryWeekPipe } from "src/app/pipes/format-delivery-week.pipe";
import { FormatStringYearPipe } from "src/app/pipes/format-string-year.pipe";
import { MinMaxValidationTitlePipe } from "src/app/pipes/min-max-validation-title.pipe";
import { SanitizeHtmlPipe } from "src/app/pipes/sanitize-html.pipe";
import { SearchArticlePipe } from "src/app/pipes/search-article.pipe";
import { SearchUserPipe } from "src/app/pipes/search-user.pipe";
import { ShippingAddressTextPipe } from "src/app/pipes/shipping-address-text.pipe";
import { ListItemComponent } from "src/app/components/search-components/tree-view/list-item/list-item.component";
import { DropshipmentCounterComponent } from "src/app/components/header-components/dropshipment-counter/dropshipment-counter.component";
import { BottomNavbarComponent } from "src/app/components/general-components/bottom-navbar/bottom-navbar.component";
import { WidgetComponent } from "src/app/components/home-page-components/widget/widget.component";
import { NgCircleProgressModule } from "ng-circle-progress";
import { AddOpeningHoursComponent } from "src/app/components/dealer-settings/add-opening-hours/add-opening-hours.component";
import { EditOpeningHoursComponent } from "src/app/components/dealer-settings/edit-opening-hours/edit-opening-hours.component";
import { ReplacementArticleComponent } from "src/app/components/general-components/replacement-article/replacement-article.component";
import { NewsDetailsComponent } from "src/app/components/cms/news-details/news-details.component";
import { NewsItemComponent } from "src/app/components/home-page-components/news-item/news-item.component";
import { NewsComponent } from "src/app/components/home-page-components/news/news.component";
import { AlertsComponent } from "src/app/components/home-page-components/alerts/alerts.component";
import { AlertItemComponent } from "src/app/components/home-page-components/alerts/alert-item/alert-item.component";
import { QuickLinksComponent } from "src/app/components/cms/quick-links/quick-links/quick-links.component";
import { ArticleGroupsBrowserComponent } from "src/app/components/home-page-components/article-groups-browser/article-groups-browser.component";
import { AddToStockNotificationButtonComponent } from "src/app/components/stock-notifications/add-to-stock-notification-button/add-to-stock-notification-button.component";
import { SelectForWarrantyButtonComponent } from "src/app/components/warranty/warranty-wizard/bom/select-for-warranty-button/select-for-warranty-button.component";
import { BarCodeIconComponent } from "src/app/components/general-components/bar-code-icon/bar-code-icon.component";
import { ThreeDeeViewerComponent } from "src/app/components/article-components/three-dee-viewer/three-dee-viewer.component";
import { FavSwiperComponent } from "src/app/components/fav-swiper/fav-swiper.component";
import { ReplenishmentAvailabilityCheckerComponent } from "src/app/components/replenishment/replenishment-availability-checker/replenishment-availability-checker.component";
import { DynamicModule } from "ng-dynamic-component";
import { AgreementAlertComponent } from "src/app/components/year-agreement/shared/agreement-alert/agreement-alert.component";
import { AgreementSelectorComponent } from "src/app/components/year-agreement/shared/agreement-selector/agreement-selector.component";
import { DealerYearAgreementsOverviewComponent } from "src/app/components/year-agreement/shared/dealer-year-agreements-overview/dealer-year-agreements-overview.component";
import { YearAgreementsBreadcrumbsComponent } from "src/app/components/year-agreement/shared/year-agreements-breadcrumbs/year-agreements-breadcrumbs.component";
import { NoResultsComponent } from "src/app/components/general-components/no-results/no-results.component";
import { LowestPriceComponent } from "src/app/components/general-components/lowest-price/lowest-price.component";
import { createTranslateLoader } from "src/app/translationLoader";
import { HttpClient } from "@angular/common/http";
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { register } from 'swiper/element/bundle';
import { ButtonModule } from 'primeng/button';
import { SliderModule } from 'primeng/slider';
import { NgxSliderModule } from 'ngx-slider-v2';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { PromotionsCarouselComponent } from "src/app/components/cms/promotions-carousel/promotions-carousel.component";
import { InputNumberModule } from 'primeng/inputnumber';
import { RippleModule } from 'primeng/ripple';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ProgressBarModule } from 'primeng/progressbar';
import { InputTextModule } from 'primeng/inputtext';
import { ScrollTopModule } from 'primeng/scrolltop';
import { PanelModule } from 'primeng/panel';
import { CreditLimitComponent } from "src/app/components/basket-components/credit-limit/credit-limit.component";
import { SidebarModule } from 'primeng/sidebar';
import {StickyModule} from 'ngx-sticky-kit';
import { AccordionModule } from 'primeng/accordion';
import { DisclaimerComponent } from "src/app/components/general/disclaimer/disclaimer.component";
import { DumbComponentComponent } from "src/app/components/general/dumb-component/dumb-component.component";
import { BrandSelectorComponent } from "src/app/components/general/brand-selector/brand-selector.component";
import { LoadingButtonComponent } from "src/app/components/general/loading-button/loading-button.component";
import { DealerSettingsContainerComponent } from "src/app/components/dealer-settings/dealer-settings-container/dealer-settings-container.component";
import { DealerSettingsOpeningHoursComponent } from "src/app/components/dealer-settings/dealer-settings-opening-hours/dealer-settings-opening-hours.component";
import { DealerStockTokenContainerComponent } from "src/app/components/dealer-settings/dealer-stock-token-container/dealer-stock-token-container.component";
import { DealerSettingsPageComponent } from "src/app/pages/dealer-settings-page/dealer-settings-page.component";
register();

@NgModule({
  imports: [
    TranslateModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgOtpInputModule,
    InterceptorModule,
    DynamicModule,
    CommonModule,
    RouterModule,
    PdfViewerModule,
    ButtonModule,
    SliderModule,
    NgxSliderModule,
    ZXingScannerModule,
    InputNumberModule,
    RippleModule,
    InputSwitchModule,
    ProgressBarModule,
    InputTextModule,
    SidebarModule,
    ScrollTopModule,
    PanelModule,
    StickyModule,
    NgxSkeletonLoaderModule,
    AccordionModule,
    NgCircleProgressModule.forRoot({
      radius: 133,
      space: -30,
      outerStrokeWidth: 30,
      innerStrokeWidth: 30,
      outerStrokeColor: "#4882c2",
      innerStrokeColor: "#e7e8ea",
      outerStrokeLinecap: "butt",
      showSubtitle: false,
    })
  ],
  declarations: [
    /* Pipes */
    StatusTextPipe,
    CurrencyConvertPipe,
    NumberFormatPipe,
    SafePipe,
    SortByPipe,
    GroupByPipe,
    DeliveryTextPipe,
    SearchUserPipe,
    FormatDeliveryWeekPipe,
    ShippingAddressTextPipe,
    FormatAccentryUrlPipe,
    MinMaxValidationTitlePipe,
    SanitizeHtmlPipe,
    FormatStringYearPipe,
    SearchArticlePipe,
    ArticleLinksComponent,
    InitialsDirectiveDirective,
    ArticleQuickViewDirective,
    BasketArticleComponent,
    NumberOnlyDirective,
    CmsPageContentComponent,
    BreadCrumbsComponent,
    SelectWeekComponent,
    SelectBasketComponent,
    ArticleImageComponent,
    StockStatusComponent,
    ShopTypeSelectionComponent,
    DeliveryWeekComponent,
    NettPriceComponent,
    ArticleSumComponent,
    RetailPriceComponent,
    BicycleArticleComponent,
    AddToBasketButtonComponent,
    NoResultsComponent,
    DealerSettingsContainerComponent,
    DealerSettingsPageComponent,
    DealerSettingsOpeningHoursComponent,
    DealerStockTokenContainerComponent,
    SetItemButtonComponent,
    LeasePriceComponent,
    DumbComponentComponent,
    CommonFilterComponent,
    ArticleImageCarouselComponent,
    InlineMatrixComponent,
    MatrixComponent,
    ArticleSpecificationsComponent,
    DealerInformationComponent,
    DisclaimerComponent,
    KioskModeCheckerComponent,
    KioskArticleCardComponent,
    CompanyPageComponent,
    KioskArticleCardComponent,
    HeaderIconComponent,
    ChangePasswordContainerComponent,
    SuccessCheckIconComponent,
    YearAgreementSummaryComponent,
    AgreementAlertComponent,
    ArticleCardComponent,
    SuggestTermsComponent,
    HighlightBadgeComponent,
    GrossPriceComponent,
    SpecListComponent,
    ArticleDetailsComponent,
    ThreeDeeViewerComponent,
    QuickSettingsComponent,
    ArticleOptionsComponent,
    ArticleStickBarComponent,
    PromotionPriceComponent,
    VolumePricesComponent,
    LowestPriceComponent,
    SetItemsComponent,
    ArticleQuestionComponent,
    EcommerceArticlesComponent,
    HasReplacementComponent,
    RecentlyViewedComponent,
    AddToLeaseButtonComponent,
    FocusModelComponent,
    TreeViewItemComponent,
    TreeViewComponent,
    FavSwiperComponent,
    ArticleBomComponent,
    SelectGroupComponent,
    SetItemGroupComponent,
    PreorderContainerComponent,
    PreorderMonthInputComponent,
    ArticleListItemComponent,
    ArticleWeeksTableComponent,
    ListItemComponent,
    DropshipmentCounterComponent,
    BottomNavbarComponent,
    WidgetComponent,
    AddOpeningHoursComponent,
    EditOpeningHoursComponent,
    ReplacementArticleComponent,
    NewsItemComponent,
    NewsComponent,
    AlertsComponent,
    AlertItemComponent,
    NewsDetailsComponent,
    QuickLinksComponent,
    ArticleGroupsBrowserComponent,
    SelectForWarrantyButtonComponent,
    AddToStockNotificationButtonComponent,
    BarCodeIconComponent,
    ReplenishmentAvailabilityCheckerComponent,
    BrandSelectorComponent,
    AgreementSelectorComponent,
    DealerYearAgreementsOverviewComponent,
    YearAgreementsBreadcrumbsComponent,
    PromotionsCarouselComponent,
    LoadingButtonComponent,
    CreditLimitComponent,
  ],
  exports: [
    NgbModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    StatusTextPipe,
    CurrencyConvertPipe,
    NumberFormatPipe,
    SafePipe,
    SortByPipe,
    GroupByPipe,
    DeliveryTextPipe,
    SearchUserPipe,
    NgCircleProgressModule,
    FormatDeliveryWeekPipe,
    ShippingAddressTextPipe,
    FormatAccentryUrlPipe,
    MinMaxValidationTitlePipe,
    SanitizeHtmlPipe,
    FormatStringYearPipe,
    SearchArticlePipe,
    ArticleLinksComponent,
    InitialsDirectiveDirective,
    ArticleQuickViewDirective,
    BasketArticleComponent,
    NumberOnlyDirective,
    CmsPageContentComponent,
    BreadCrumbsComponent,
    SelectWeekComponent,
    SelectBasketComponent,
    ArticleImageComponent,
    StockStatusComponent,
    ShopTypeSelectionComponent,
    DeliveryWeekComponent,
    NettPriceComponent,
    ArticleSumComponent,
    RetailPriceComponent,
    BicycleArticleComponent,
    AddToBasketButtonComponent,
    NoResultsComponent,
    SetItemButtonComponent,
    LeasePriceComponent,
    DumbComponentComponent,
    CommonFilterComponent,
    ArticleImageCarouselComponent,
    InlineMatrixComponent,
    MatrixComponent,
    ArticleSpecificationsComponent,
    DealerInformationComponent,
    DisclaimerComponent,
    KioskModeCheckerComponent,
    KioskArticleCardComponent,
    CompanyPageComponent,
    KioskArticleCardComponent,
    HeaderIconComponent,
    ChangePasswordContainerComponent,
    DealerSettingsContainerComponent,
    DealerSettingsPageComponent,
    DealerSettingsOpeningHoursComponent,
    DealerStockTokenContainerComponent,
    SuccessCheckIconComponent,
    YearAgreementSummaryComponent,
    AgreementAlertComponent,
    ArticleCardComponent,
    SuggestTermsComponent,
    HighlightBadgeComponent,
    GrossPriceComponent,
    SpecListComponent,
    ArticleDetailsComponent,
    ThreeDeeViewerComponent,
    QuickSettingsComponent,
    ArticleOptionsComponent,
    ArticleStickBarComponent,
    PromotionPriceComponent,
    VolumePricesComponent,
    LowestPriceComponent,
    SetItemsComponent,
    ArticleQuestionComponent,
    EcommerceArticlesComponent,
    HasReplacementComponent,
    RecentlyViewedComponent,
    AddToLeaseButtonComponent,
    FocusModelComponent,
    TreeViewItemComponent,
    TreeViewComponent,
    FavSwiperComponent,
    ArticleBomComponent,
    SelectGroupComponent,
    SetItemGroupComponent,
    PreorderContainerComponent,
    PreorderMonthInputComponent,
    ArticleListItemComponent,
    ArticleWeeksTableComponent,
    ListItemComponent,
    DropshipmentCounterComponent,
    BottomNavbarComponent,
    WidgetComponent,
    AddOpeningHoursComponent,
    EditOpeningHoursComponent,
    ReplacementArticleComponent,
    NewsItemComponent,
    NewsComponent,
    AlertsComponent,
    AlertItemComponent,
    NewsDetailsComponent,
    QuickLinksComponent,
    ArticleGroupsBrowserComponent,
    SelectForWarrantyButtonComponent,
    AddToStockNotificationButtonComponent,
    BarCodeIconComponent,
    ReplenishmentAvailabilityCheckerComponent,
    BrandSelectorComponent,
    AgreementSelectorComponent,
    DealerYearAgreementsOverviewComponent,
    YearAgreementsBreadcrumbsComponent,
    LoadingButtonComponent,
    NgbNavModule,
    PdfViewerModule,
    ButtonModule,
    SliderModule,
    NgxSliderModule,
    ZXingScannerModule,
    PromotionsCarouselComponent,
    InputNumberModule,
    RippleModule,
    InputSwitchModule,
    ProgressBarModule,
    InputTextModule,
    ScrollTopModule,
    SidebarModule,
    PanelModule,
    CreditLimitComponent,
    NgxSkeletonLoaderModule,
    StickyModule,
    AccordionModule
  ],
})
export class SharedModule {}
