<app-bread-crumbs
  [(breadcrumbs)]="breadcrumbs"
  *ngIf="!ordered"
></app-bread-crumbs>
<div class="container">
  <div class="mx-0 row">
    <app-basket-skeleton-loader *ngIf="!loaded && !basket" />
    <div class="ps-0 pe-2 col-md-12" *ngIf="basket && !ordered">
      <div class="info p-3 row">
        <div class="col-12 col-lg-6">
          <h1 class="mt-0 d-flex">
            <app-header-icon iconName="local_shipping"></app-header-icon>
            {{ "CHECKOUT" | translate }} &centerdot; {{ basket.items?.length }}
            {{
              (basket.items?.length === 1 ? "ARTICLE" : "ARTICLES") | translate
            }}
            <span
              class="px-2 mx-1 badge bg-warning badge-pill"
              *ngIf="basket.pre_order"
              >{{ "PREORDER" | translate }}</span
            >
          </h1>
          <h2 class="pb-2 subHeader" *ngIf="!basket.pre_order">
            {{ basket.name }}
          </h2>
        </div>
        <div class="col-12 col-lg-6 pt-2">
          <div class="d-flex flex-column pb-3" *ngIf="basket.shipping_address">
            <h5>{{ "SHIPPINGADDRESS" | translate }}:</h5>
            <strong class="d-block">{{ basket.shipping_address.name }}</strong>
            <span>{{ basket.shipping_address.address }}</span>
            {{ basket.shipping_address.zip }} {{ basket.shipping_address.city }}
          </div>
          <p
            *ngIf="basket.pre_order"
            [innerHTML]="'PREORDER_INTRO_' + companyGroupCode | translate"
          ></p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-9 ps-2">
          <!-- Locked basket overlay -->
          <div
            class="top-0 p-3 basket-locked position-absolute w-100 h-100 d-flex justify-content-center align-items-center m-n3"
            *ngIf="basket.locked"
          >
            <span class="me-2 material-icons text-danger"> lock </span>
            <span class="me-3">{{ "BASKET_LOCKED" | translate }}</span
            ><button
              class="btn btn-sm btn-outline-primary"
              (click)="unlockBasket()"
            >
              {{ "UNLOCK_BASKET" | translate }}
            </button>
          </div>

          <app-unchecked-articles-notice
            *ngIf="basket.unchecked_article_type === 'Parts' || basket.unchecked_article_type === 'Multiple'"
            [type]="'PARTS'"
          ></app-unchecked-articles-notice>

          <!-- Parts -->
          <app-grouped-articles
            *ngIf="
              groupedArticles &&
              groupedArticles['parts'] &&
              groupedArticles['parts'].items.length
            "
            class="d-block"
            (contentChanged)="calcTotal(basket.items)"
            [groupedArticles]="groupedArticles['parts']"
            [currency]="basket.total_price?.currency"
            [header]="'PARTS_ACCESSORIES'"
            [sum]="basketSum['parts']"
            [sum_available]="basketSum['parts_available']"
            [compact]="false"
            [type]="'parts'"
            [basketId]="basket.id"
            [checkout]="true"
            [coupons]="coupons"
          ></app-grouped-articles>

          <app-unchecked-articles-notice
            *ngIf="basket.unchecked_article_type === 'Bikes' || basket.unchecked_article_type === 'Multiple'"
            [type]="'BICYCLES'"
          ></app-unchecked-articles-notice>


          <!-- Bicycles -->
          <app-grouped-articles
            *ngIf="
              groupedArticles &&
              groupedArticles['bicycles'] &&
              groupedArticles['bicycles'].items.length
            "
            class="d-block"
            [type]="'bicycles'"
            (contentChanged)="calcTotal(basket.items)"
            [groupedArticles]="groupedArticles['bicycles']"
            [currency]="basket.total_price?.currency"
            [header]="'BICYCLES'"
            [sum]="basketSum['bicycles']"
            [compact]="false"
            [basketId]="basket.id"
            [checkout]="true"
          ></app-grouped-articles>

          <!-- Preorder -->
          <ng-container *ngIf="basket.pre_order">
            <div class="d-flex row">
              <app-grouped-articles
                class="col-md-12 d-block w-100 ps-0"
                [style.order]="0"
                *ngIf="
                  groupedArticlesByDirectDelivery &&
                  groupedArticlesByDirectDelivery.items.length &&
                  basketSumDirect
                "
                [groupedArticles]="groupedArticlesByDirectDelivery"
                [header]="'DELIVER_WHEN_POSSIBLE'"
                [sum]="basketSumDirect['direct']"
                [compact]="false"
                [basketId]="basket.id"
                [checkout]="true"
                [preorder]="true"
                [currency]="basket.total_price?.currency"
                [showShopDropdown]="basket.article_shop_dropdown"
                [lockedDeliveryWeeks]="basket.locked_delivery_week_all"
              >
              </app-grouped-articles>

              <ng-container *ngFor="let m of totalMonths">
                <app-grouped-articles
                  class="col-md-12 d-block w-100 ps-0"
                  [style.order]="
                    groupedArticlesByMonth[m].items[0].delivery_year -
                    2000 +
                    '' +
                    applicationService.getTimeslotFromMonth(
                      m.toString() | slice : 4 : 6
                    )
                  "
                  *ngIf="
                    groupedArticlesByMonth &&
                    groupedArticlesByMonth[m] &&
                    groupedArticlesByMonth[m].items.length
                  "
                  (contentChanged)="calcTotal(basket.items)"
                  [groupedArticles]="groupedArticlesByMonth[m]"
                  [header]="
                    individualPreorderCheckout
                      ? 'TIMESLOT_' +
                        applicationService.getTimeslotFromMonth(
                          m.toString() | slice : 4 : 6
                        )
                      : 'MONTH_' + (m.toString() | slice : 4 : 6)
                  "
                  [sum]="basketSumMonths[m]"
                  [compact]="false"
                  [basketId]="basket.id"
                  [checkout]="true"
                  [preorder]="true"
                  [currency]="basket.total_price?.currency"
                  [showShopDropdown]="basket.article_shop_dropdown"
                  [lockedDeliveryWeeks]="basket.locked_delivery_week_all"
                >
                  {{ groupedArticlesByMonth[m].items[0].delivery_year }}
                </app-grouped-articles>
              </ng-container>
              <app-grouped-articles
                class="col-md-12 d-block w-100 ps-0"
                [style.order]="2998"
                *ngIf="
                  groupedArticlesByMonth &&
                  groupedArticlesByMonth['demo'] &&
                  groupedArticlesByMonth['demo'].items.length
                "
                (contentChanged)="calcTotal(basket.items)"
                [allArticles]="basket.items"
                [showCheckoutOption]="!individualPreorderCheckout"
                [groupedArticles]="groupedArticlesByMonth['demo']"
                [header]="'DEMO'"
                [sum]="basketSumMonths['demo']"
                [checkout]="true"
                [basketId]="basket.id"
                [preorder]="true"
                [groupId]="'DEMO'"
                [currency]="basket.total_price?.currency"
                [showShopDropdown]="basket.article_shop_dropdown"
                [lockedDeliveryWeeks]="basket.locked_delivery_week_all"
              >
                {{ groupedArticlesByMonth["demo"].items[0].delivery_year }}
              </app-grouped-articles>
              <app-grouped-articles
                class="col-md-12 d-block w-100 ps-0"
                [style.order]="2999"
                *ngIf="
                  groupedArticlesByMonth &&
                  groupedArticlesByMonth['repair'] &&
                  groupedArticlesByMonth['repair'].items.length
                "
                (contentChanged)="calcTotal(basket.items)"
                [allArticles]="basket.items"
                [showCheckoutOption]="!individualPreorderCheckout"
                [groupedArticles]="groupedArticlesByMonth['repair']"
                [header]="'REPAIR'"
                [sum]="basketSumMonths['repair']"
                [checkout]="true"
                [basketId]="basket.id"
                [preorder]="true"
                [groupId]="'REPAIR'"
                [currency]="basket.total_price?.currency"
                [showShopDropdown]="basket.article_shop_dropdown"
                [lockedDeliveryWeeks]="basket.locked_delivery_week_all"
              >
                {{ groupedArticlesByMonth["repair"].items[0].delivery_year }}
              </app-grouped-articles>
            </div>
          </ng-container>
        </div>
        <div class="col-12 col-sm-3">
          <sticky
            id="sticky"
            #sticky
            [sticky-media-query]="'min-width: 576px'"
            [sticky-offset-top]="fixedNavBar ? 100 : 0"
            [style.min-width]="stickyWidth ? stickyWidth + 'px' : 0"
          >
            <h4 class="mb-3">&nbsp;</h4>
            <div
              class="px-2 p-sm-3 list-group list-group-flush col-md-12 sum totalSum"
              *ngIf="basketSum.totalWithShipping > 0"
            >
              <div class="col-12 px-sm-2">
                <div class="mb-1 row px-2 px-sm-0">
                  <div
                    class="px-2 text-end col-md-12 shippingSum"
                    *ngIf="
                      !basket.shipping_costs_mandatory &&
                      basket.shipping_cost &&
                      !basket.pre_order &&
                      applicationService.getSelectCompanyGroupCode() !== 'WG'
                    "
                  >
                    {{ "SHIPPINGCOST" | translate }}
                    <span
                      *ngIf="basket.shipping_cost && !basket.pre_order"
                      [innerHTML]="
                        basket.shipping_cost.value
                          | currencyConvert : basket.shipping_cost?.currency
                      "
                    ></span>
                  </div>
                </div>
                <div class="mb-2 row px-2" *ngIf="total_coupon_discount">
                  <div class="px-0 text-end col-md-12 shippingSum">
                    {{ "TOTAL_COUPONS" | translate }}
                    <span
                      class="d-sm-block d-md-inline-block"
                      [innerHTML]="
                        -total_coupon_discount
                          | currencyConvert : basket.total_price?.currency
                      "
                    ></span>
                  </div>
                </div>
                <div class="row">
                  <app-credit-limit *ngIf="companyGroupCode === 'RAL' && !showroomMode"
                    class="px-0"
                    [currency]="basket.total_price?.currency"
                  />
                </div>
                <div class="row px-2">
                  <div class="px-0 col-12 totalSum">
                    <div
                      class="col-md-12 totalSum d-flex flex-column justify-content-end align-items-end"
                    >
                      <div class="d-block w-100">
                        {{ "PARTS_ACCESSORIES" | translate }}:
                        {{
                          basketSum?.parts
                            | currencyConvert : basket.total_price?.currency
                        }}
                      </div>
                      <div class="d-block w-100">
                        {{ "BICYCLES" | translate }}:
                        {{
                          basketSum?.bicycles
                            | currencyConvert : basket.total_price?.currency
                        }}
                      </div>
                      <div
                        class="d-block w-100 border-top border-dark-subtle total-text pt-2 mt-2"
                      >
                        {{ "TOTAL" | translate }}:
                        <span
                          class="d-md-inline-block"
                          [innerHTML]="
                            basketSum.totalWithShipping
                              | currencyConvert : basket.total_price?.currency
                          "
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </sticky>
        </div>
        <div
          *ngIf="basket.shipping_costs_mandatory && (groupedArticles?.parts?.items?.length || groupedArticles?.bicycles?.items?.length)"
          class="shippingSelectContainer p-3 col-12 col-sm-6 col-lg-6 col-xl-4 ms-auto"
        >
          <h3>
            <span class="material-icons" style="transform: translateY(1.5px)"
              >date_range</span
            >
            {{ "SHIPMENT_DATE" | translate }}
          </h3>

          <ng-container
            *ngIf="
              basket.selected_shipping_costs?.Parts ||
                basket.selected_shipping_costs?.Bicycles;
              else noOptionsAlerts
            "
          >
            <app-select-checkout-shipping-date
              *ngIf="
                groupedArticles &&
                groupedArticles['parts'] &&
                groupedArticles['parts'].items.length
              "
              [shippingDateOptions]="basket.selected_shipping_costs.Parts"
              [label]="'PARTS_ACCESSORIES' | translate"
              (dateSelected)="shippingDateParts = $event"
            ></app-select-checkout-shipping-date>
            <app-select-checkout-shipping-date
              *ngIf="
                groupedArticles &&
                groupedArticles['bicycles'] &&
                groupedArticles['bicycles'].items.length
              "
              [shippingDateOptions]="basket.selected_shipping_costs.Bicycles"
              [label]="'BICYCLES' | translate"
              (dateSelected)="shippingDateBicycles = $event"
            ></app-select-checkout-shipping-date>
          </ng-container>
          <ng-template #noOptionsAlerts>
            <ngb-alert type="danger" [dismissible]="false">{{
              "NO_SHIPPING_OPTIONS_FOUND" | translate
            }}</ngb-alert>
          </ng-template>
        </div>
      </div>
    </div>
    <div class="px-3 col-md-12" *ngIf="ordered">
      <app-sent-basket-overview
        [basketId]="basketId" [basket]="orderedBasket" [unOrderedArticles]="unOrderedArticles"
      ></app-sent-basket-overview>
    </div>
  </div>


  <ng-template #options>
    <div
      class="px-0 col-md-12 d-flex justify-content-between align-items-center"
      *ngIf="basket && !ordered"
    >
      <button class="me-auto btn btn-lg btn-dark" (click)="back()">
        {{ "BACK" | translate }}
      </button>
      <div
        *ngIf="
          basket.pre_order &&
            (companyGroupCode === 'WG' || companyGroupCode === 'GHO');
          then gopaycond;
          else gocheckout
        "
      ></div>
      <ng-template #gopaycond>
        <button
          class="btn btn-lg btn-success"
          (click)="paycond()"
          [disabled]="
            !basket.ordering_allowed || basket.locked ? 'disabled' : ''
          "
          [ngClass]="
            !basket.ordering_allowed || basket.locked ? 'disabled' : ''
          "
        >
          {{ "NEXT" | translate }}
        </button>
      </ng-template>
      <ng-template #gocheckout>
        <button
          class="me-3 btn btn-lg btn-success"
          [disabled]="
            (!basket.ordering_allowed ||
            clicked ||
            basket.locked ||
            !validShippingDates
              ? 'disabled'
              : '') || !loaded
          "
          [ngClass]="[
            !basket.ordering_allowed || basket.locked || !validShippingDates || !loaded
              ? 'disabled'
              : '',
              !loaded ? 'loading' : '']
          "
          *ngIf="showPaymentButton && paymentAllowed"
          (click)="goToPaymentUrl()"
        >
          {{ "PAY_AND_SEND" | translate }}
        </button>
        <ngb-alert type="danger" [dismissible]="false" *ngIf="showPaymentButton && !paymentAllowed" class="me-2 mb-0">
          {{"PAYMENT_NOT_ALLOWED_BICYCLED_PARTS_COMBINED" | translate}}
        </ngb-alert>
        <button
          class="btn btn-lg btn-success"
          style="min-width: 15%; max-width: 40vw"
          *ngIf="
            !ordered &&
            (onlinePaymentRestrictionLevel === 0 ||
              onlinePaymentRestrictionLevel === 2 ||
              onlinePaymentRestrictionLevel === 3)
          "
          (click)="checkout()"
          [disabled]="
            !basket.ordering_allowed ||
            clicked ||
            basket.locked ||
            !validShippingDates
              ? 'disabled'
              : ''
          "
          [ngClass]="[
            !basket.ordering_allowed || basket.locked || !validShippingDates
              ? 'disabled'
              : '',
            !loaded ? 'loading' : ''
          ]"
          [ngbTooltip]="
            (!basket.ordering_allowed
              ? 'ACCOUNT_ORDERING_NOT_ALLOWED'
              : 'BASKET_LOCKED'
            ) | translate
          "
          [disableTooltip]="basket.ordering_allowed"
          [closeDelay]="999999"
          [autoClose]="false"
          [tooltipClass]="'not_allowed_tooltip'"
          #tooltip_checkout="ngbTooltip"
        >
          <span>
            {{ "SEND" | translate }}
          </span>
        </button>
      </ng-template>
    </div>
  </ng-template>
</div>
<app-bottom-navbar [fixed]="true" *ngIf="showNavbar">
  <ng-container *ngTemplateOutlet="options"></ng-container>
</app-bottom-navbar>

