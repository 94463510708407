<app-bread-crumbs [(breadcrumbs)]="breadcrumbs"></app-bread-crumbs>
<div class="container mb-5">
  <div class="row" [ngClass]="{ 'first-signin': firstSignin }">
    <div class="col-12" *ngIf="(firstSignin && !firstSigninSuccess) || !firstSignin"
      [ngClass]="[accountSettings.okta ? 'has-okta' : '']">
      <h1>
        <app-header-icon iconName="settings" *ngIf="!isVerify"></app-header-icon>
        {{ (!isVerify ? "SETTINGS" : "ACTIVATE_TITLE") | translate }}
      </h1>
      <nav ngbNav #nav="ngbNav" justify="center" class="nav-pills">
        <ng-container ngbNavItem>
          <a ngbNavLink>{{ !firstSignin ? ("SETTINGS" | translate) : "" }}</a>
          <ng-template ngbNavContent>
            <div class="col-md-12 bg-gray p-3 rounded" *ngIf="loaded">
              <p class="mb-4" *ngIf="!isVerify">
                {{ "INTRO_SETTINGS" | translate }}
              </p>
              <form id="form-settings" class="mx-0 mb-3 row" ngNativeValidate>
                <div class="pb-2 w-100 form-row-group row" *ngIf="showDealerOptions">
                  <div class="col-lg-4 col-xl-3">
                    <label class="fw-bold">
                      {{ "SHOWROOM_MODE" | translate }}
                    </label>
                  </div>
                  <div class="col-lg-8">
                    <input id="showSm" name="showroomMode" type="checkbox"
                      [(ngModel)]="accountSettings.showroom_mode" />
                    <label for="showSm" class="ms-1">
                      {{ "SHOW_BUTTON" | translate }}
                    </label>
                    <br />
                    <input id="defaultSm" name="showroomModeDefault" type="checkbox"
                      [(ngModel)]="accountSettings.showroom_mode_default" />
                    <label for="defaultSm" class="ms-1">
                      {{ "SHOWROOM_MODE_STATUS" | translate }}
                    </label>
                  </div>
                </div>

                <div class="pb-2 w-100 form-row-group row" *ngIf="!firstSignin && showDealerOptions">
                  <div class="col-lg-4 col-xl-3">
                    <label class="fw-bold">
                      {{ "DEFAULTCOMPANYGROUP" | translate }}
                    </label>
                  </div>
                  <div class="col-lg-8">
                    <select name="defaultCompany" [(ngModel)]="accountSettings.company_group" class="form-control">
                      <option *ngFor="let company of companies" [ngValue]="company.company_group_code">
                        {{ company.company_group_name.default }}
                      </option>
                    </select>
                  </div>
                </div>
                <!-- Okta users can only change their username (which is their e-mailaddress, so secretEmail isn't needed) -->
                <ng-container *ngIf="accountSettings.okta; else nonOkta">
                  <ng-container *ngTemplateOutlet="userdetails"></ng-container>
                  <div class="pb-2 w-100 form-row-group row">
                    <ng-container *ngIf="showDealerOptions">
                      <div class="col-lg-4 col-xl-3">
                        <label class="fw-bold">
                          {{ "USERNAME" | translate }}
                          <ng-container *ngIf="accountSettings.okta">
                            ({{ "EMAIL" | translate }})
                          </ng-container>
                        </label>
                      </div>
                      <div class="col-lg-8">
                        <input class="form-control" name="username" type="text" (focus)="usernameFocus = true"
                          [(ngModel)]="accountSettings.username" class="form-control"
                          [ngClass]="{ 'border-danger': emailInvalid }" required />
                      </div>
                    </ng-container>
                  </div>
                </ng-container>

                <!-- Non-okta users can only change their (secret) emailaddress -->
                <ng-template #nonOkta>
                  <div class="pb-2 w-100 form-row-group row" *ngIf="!isVerify">
                    <div class="col-lg-4 col-xl-3">
                      <label class="fw-bold">
                        {{ "EMAIL" | translate }}
                      </label>
                    </div>
                    <div class="col-lg-8">
                      <input class="form-control" name="secretEmail" type="text"
                        [(ngModel)]="accountSettings.secret_email" class="form-control" required />
                    </div>
                  </div>
                </ng-template>
                <ng-container *ngIf="!accountSettings.okta">
                  <div class="pb-2 w-100 form-row-group row" *ngIf="firstSignin">
                    <ng-container *ngTemplateOutlet="userdetails"></ng-container>
                    <div class="col-lg-4 col-xl-3">
                      <label class="fw-bold">
                        {{ "SECRETEMAIL" | translate }}
                      </label>
                    </div>
                    <div class="col-lg-8">
                      <input class="form-control" name="secretEmail" #secretEmail autocomplete="off"
                        [(ngModel)]="accountSettings.secret_email" required />
                    </div>
                  </div>
                  <ng-container *ngIf="firstSignin && !accountService.isHelpdesk()">
                    <app-change-password-container #passwordContainer
                      [changePasswordContainer]="changePasswordContainer"></app-change-password-container>
                  </ng-container>
                  <div class="pb-2 w-100 form-row-group row" *ngIf="!firstSignin">
                    <div class="col-lg-4 col-xl-3">
                      <label class="fw-bold">
                        {{ "OPTIONS" | translate }}
                      </label>
                    </div>
                    <div class="col-lg-8" *ngIf="!firstSignin">
                      <u (click)="changePassword()" class="clickable withOpacity text-info"><i class="material-icons">
                          https </i>
                        {{ "UPDATEPASSWORD" | translate }}</u>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="accountSettings.okta">
                  <div class="pb-2 w-100 form-row-group row">
                    <div class="col-lg-4 col-xl-3">
                      <label class="fw-bold">
                        {{ "RESETMAIL" | translate }}
                      </label>
                    </div>
                    <div class="col-lg-8">
                      <span class="mb-3 btn btn-outline btn-outline-primary my-sm-0 mb-md-0" (click)="resetOkta()">{{
                        "RESET" | translate }}</span>
                    </div>
                  </div>
                </ng-container>
                <div class="col-3"></div>
                <div class="col-7 d-flex ps-1 pt-3">
                  <input type="submit" [value]="'SAVE' | translate" class="btn btn-success btn-lg  ms-1"
                    (click)="updateAccountSettings($event)" [attr.disabled]="
                      ((firstSignin || !accountSettings.okta) &&
                        isVerify &&
                        passwordContainer &&
                        !passwordContainer.passwordMeetsRequirements) ||
                      emailInvalid
                        ? 'disabled'
                        : undefined
                    " />
                </div>
              </form>
            </div>
          </ng-template>
        </ng-container>
        <ng-container ngbNavItem>
          <a ngbNavLink *ngIf="!isVerify">{{ "LANGUAGE" | translate }}</a>
          <ng-template ngbNavContent>
            <div class="mt-3 col-md-12 p-3 rounded bg-gray" *ngIf="loaded">
              <div class="pb-2 w-100 form-row-group row">
                <div class="col-lg-4 col-xl-3">
                  <label class="fw-bold">
                    {{ "LANGUAGE" | translate }}
                  </label>
                </div>
                <div class="col-lg-8">
                  <select name="language" (change)="languageChanged = true" [(ngModel)]="accountSettings.language_code"
                    class="form-control">
                    <option *ngFor="let language of languages" [ngValue]="language.code">
                      {{ language.description }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row">
                <div class="col-3"></div>
                <div class="col-4 d-flex justify-content-start ps-2 mt-3">
                  <input type="submit" [value]="'SAVE' | translate" class="btn btn-success btn-lg "
                    (click)="updateLanguage()" />
                </div>
              </div>
            </div>
          </ng-template>
        </ng-container>
        <ng-container ngbNavItem *ngIf="accountSettings.okta && showDealerOptions">
          <a ngbNavLink>{{ "LINK_EMAIL_TITLE" | translate }}</a>
          <ng-template ngbNavContent>
            <div class="p-3 col-md-12 rounded bg-gray">
              <p>
                {{ "LINK_EXPLAIN_3" | translate }}
                {{ "LINK_EXPLAIN_4" | translate }}
                {{ "LINK_EXPLAIN_5" | translate }}
              </p>
              <form name="linkForm" class="mx-0 row" id="linkForm" (ngSubmit)="lf.form.valid && link()" #lf="ngForm"
                novalidate>
                <div class="pb-2 w-100 form-row-group row">
                  <div class="col-lg-3">
                    <label class="fw-bold" for="username">{{
                      "USERNAME" | translate
                      }}</label>
                  </div>
                  <div class="col-lg-9" [ngClass]="{ 'has-error': lf.submitted && !username.valid }">
                    <input type="text" class="form-control" name="username" [(ngModel)]="model.username"
                      #username="ngModel" required />
                    <div *ngIf="lf.submitted && !username.valid" class="help-block">
                      {{ "REQUIRED" | translate }}
                    </div>
                  </div>
                </div>

                <div class="pb-2 w-100 form-row-group row">
                  <div class="col-lg-3">
                    <label class="fw-bold" for="password">{{
                      "PASSWORD" | translate
                      }}</label>
                  </div>
                  <div class="col-lg-9" [ngClass]="{ 'has-error': lf.submitted && !password.valid }">
                    <input type="password" class="form-control" name="password" [(ngModel)]="model.password"
                      #password="ngModel" required autocomplete="new-password" />
                    <div *ngIf="lf.submitted && !password.valid" class="help-block">
                      {{ "REQUIRED" | translate }}
                    </div>
                  </div>
                </div>
                <div class="pb-2 w-100 form-row-group row">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3">
                    <button *ngIf="closed" class="btn btn-block btn-outline btn-outline-primary">
                      {{ "LINK" | translate }}
                    </button>
                  </div>
                  <div class="col-lg-6"></div>
                </div>
              </form>

              <div class="pt-5 pb-2 w-100 form-row-group row" *ngIf="linkedAccounts?.length > 1">
                <div class="col-lg-3"></div>
                <div class="col-lg-9">
                  <h5 class="card-title">
                    {{ "LINKED_ACCOUNTS" | translate }}
                  </h5>
                  {{ "LINK_EXPLAIN_1" | translate }}
                  {{ "LINK_EXPLAIN_2" | translate }}
                </div>
              </div>
              <div class="pb-2 w-100 form-row-group row" *ngIf="linkedAccounts?.length > 1">
                <div class="col-lg-3"></div>
                <div class="col-lg-9">
                  <ul class="list-group" *ngFor="let dealer of linkedAccounts">
                    <li class="mb-2 list-group-item d-flex justify-content-between align-items-center">
                      {{ dealer.dealer_name }} - {{ dealer.dealer_code }} <br />
                      {{ dealer.address.street }}
                      {{ dealer.address.housenumber }}
                      {{ dealer.address.housenumber_addition }}<br />
                      {{ dealer.address.zip }} {{ dealer.address.city }}<br />
                      <span class="badge bg-success badge-pill" *ngIf="dealer.active">{{ "ACTIVE" | translate }}</span>
                      <span class="btn btn-outline btn-outline-danger my-sm-0" *ngIf="!dealer.active"
                        (click)="unlinkPopup(dealer)">{{ "UNLINK" | translate }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </ng-template>
        </ng-container>
        <ng-container ngbNavItem>
          <a ngbNavLink>{{ "STOCK_LINK" | translate }}</a>
          <ng-template ngbNavContent>
            <app-dealer-settings-container></app-dealer-settings-container>
          </ng-template>
        </ng-container>

        <ng-container ngbNavItem *ngIf="accountSettings.account_code === 'ACCTEST01'">
          <a ngbNavLink>{{ "KIOSKMODE" | translate }}</a>
          <ng-template ngbNavContent>
            <div class="col-md-12 p-3 rounded bg-gray" *ngIf="loaded">
              <form ngNativeValidate>
                <p class="mb-4">{{ "KIOSK_SETTINGS_TEXT" | translate }}</p>
                <div class="pb-2 w-100 form-row-group row">
                  <div class="col-lg-3">
                    <label class="fw-bold" for="kiosk_pin">{{
                      "KIOSK_PINCODE" | translate
                      }}</label>
                  </div>
                  <div class="col-lg-9">
                    <input type="text" class="form-control" pattern="[0-9]{5}"
                      [placeholder]="'SET_YOUR_PIN' | translate" name="kiosk_pin" [(ngModel)]="kioskPin" minlength="5"
                      maxlength="5" required />
                  </div>
                </div>
                <input type="submit" [value]="'SAVE' | translate" class="btn btn-success btn-lg"
                  (click)="updateKioskPin()" />
              </form>
            </div>
          </ng-template>
        </ng-container>
      </nav>
      <div [ngbNavOutlet]="nav" class="mt-2"></div>

      <ngb-alert *ngIf="!closed" type="warning" (close)="closed = true">{{
        alert
        }}</ngb-alert>
    </div>
    <div *ngIf="firstSignin && firstSigninSuccess" style="min-width: 400px; max-width: 100%">
      <app-success-check-icon>
        <b class="mt-2 text-center w-100 d-block">{{ "SAVED" | translate }}</b>
        <u><a [routerLink]="'/login'" class="px-0 mt-3 d-block btn text-primary forgot btn-link">
            {{ "BACK_TO_LOGIN" | translate }}</a></u>
      </app-success-check-icon>
    </div>
  </div>
</div>
<ng-template #userdetails>
  <div class="pb-2 w-100 form-row-group row">
    <div class="col-lg-4 col-xl-3">
      <label class="fw-bold">
        {{ "FIRSTNAME" | translate }}
      </label>
    </div>
    <div class="col-lg-8">
      <input class="form-control" name="firstname" type="text" [(ngModel)]="accountSettings.first_name"
        class="form-control" required />
    </div>
  </div>
  <div class="pb-2 w-100 form-row-group row">
    <div class="col-lg-4 col-xl-3">
      <label class="fw-bold">
        {{ "MIDDLENAME" | translate }}
      </label>
    </div>
    <div class="col-lg-8">
      <input class="form-control" name="middlename" type="text" [(ngModel)]="accountSettings.middle_name" maxlength="10"
        class="form-control" style="max-width: 100px" />
    </div>
  </div>
  <div class="pb-2 w-100 form-row-group row">
    <div class="col-lg-4 col-xl-3">
      <label class="fw-bold">
        {{ "LASTNAME" | translate }}
      </label>
    </div>
    <div class="col-lg-8">
      <input class="form-control" name="lastname" type="text" [(ngModel)]="accountSettings.last_name"
        class="form-control" required />
    </div>
  </div>
</ng-template>
